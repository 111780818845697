import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f712ae2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "grid m-0" }
const _hoisted_3 = { class: "col-12 p-0" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  class: "flex m-0 px-8",
  style: {"align-items":"center","gap":"20%"}
}
const _hoisted_6 = {
  class: "flex",
  style: {"flex-direction":"column","align-items":"center","border-style":"none"}
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = {
  class: "flex mt-3 pointer",
  style: {"justify-content":"space-between","width":"calc(100% + 10px)"}
}
const _hoisted_10 = {
  class: "flex pointer",
  style: {"justify-content":"space-between","margin-top":"14px"}
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotApplicableRadio = _resolveComponent("NotApplicableRadio")!
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", {
      class: "m-0",
      innerHTML: _ctx.$tc('questionnaireForm.statementContent')
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "question-field text-center font-bold",
          innerHTML: _ctx.$tc('questionnaireForm.q' + this.questionIndex)
        }, null, 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_NotApplicableRadio, {
          style: {"display":"flex","justify-content":"center","height":"fit-content","margin-top":"-5px"},
          click: _ctx.setNotApplicable,
          selected: _ctx.checked
        }, null, 8, ["click", "selected"]),
        _createElementVNode("div", {
          class: "pointer",
          style: {"min-width":"150px","margin-top":"34px"},
          innerHTML: _ctx.$t('questionnaireForm.labelNotApplicable')
        }, null, 8, _hoisted_7)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Slider, {
          modelValue: _ctx.qvalue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.qvalue) = $event)),
          min: 0,
          step: 1,
          max: 6,
          class: "",
          style: {"width":"100% !important"},
          onChange: _cache[1] || (_cache[1] = ($event: any) => {_ctx.changeEvent();})
        }, null, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateSlider(0)))
          }),
          _createElementVNode("span", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateSlider(1)))
          }, "1"),
          _createElementVNode("span", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateSlider(2)))
          }, "2"),
          _createElementVNode("span", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateSlider(3)))
          }, "3"),
          _createElementVNode("span", {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateSlider(4)))
          }, "4"),
          _createElementVNode("span", {
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateSlider(5)))
          }, "5"),
          _createElementVNode("span", {
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateSlider(6)))
          }, "6")
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateSlider(1))),
            style: {"margin-left":"20px"},
            innerHTML: _ctx.$t('questionnaireForm.labelCompletelyDisagree')
          }, null, 8, _hoisted_11),
          _createElementVNode("div", {
            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateSlider(6))),
            style: {"margin-right":"-42px"},
            innerHTML: _ctx.$t('questionnaireForm.labelCompletelyAgree')
          }, null, 8, _hoisted_12)
        ])
      ])
    ])
  ], 64))
}