import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-592f95ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  class: "form-block flex",
  style: {"flex-direction":"column","gap":"40px"}
}
const _hoisted_4 = {
  class: "flex",
  style: {"align-items":"center","gap":"32px","justify-content":"space-between","width":"501px"}
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  style: {"display":"flex","gap":"16px","align-items":"center"}
}
const _hoisted_7 = { style: {"display":"inline-block","font-weight":"bold","text-align":"left"} }
const _hoisted_8 = { style: {"display":"inline-block"} }
const _hoisted_9 = {
  class: "fields-block",
  style: {"display":"flex","flex-direction":"column","gap":"8px"}
}
const _hoisted_10 = {
  class: "flex",
  style: {"gap":"12px"}
}
const _hoisted_11 = {
  class: "flex",
  style: {"flex-direction":"column","gap":"6px"}
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "input-first-name" }
const _hoisted_14 = {
  class: "flex",
  style: {"flex-direction":"column","gap":"6px"}
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "input-last-name" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "input-email" }
const _hoisted_19 = {
  class: "flex",
  style: {"gap":"12px"}
}
const _hoisted_20 = {
  class: "flex",
  style: {"flex-direction":"column","gap":"6px"}
}
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "input-job-title" }
const _hoisted_23 = {
  class: "flex",
  style: {"flex-direction":"column","gap":"6px"}
}
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { class: "input-job-level" }
const _hoisted_26 = {
  class: "flex",
  style: {"gap":"12px"}
}
const _hoisted_27 = {
  class: "flex",
  style: {"flex-direction":"column","gap":"6px"}
}
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = { class: "input-phone" }
const _hoisted_30 = {
  class: "flex",
  style: {"flex-direction":"column","gap":"6px"}
}
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = { class: "input-language" }
const _hoisted_33 = { class: "button-submit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_IciButton = _resolveComponent("IciButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "form-title",
            innerHTML: _ctx.$tc('profileForm.title')
          }, null, 8, _hoisted_5),
          (_ctx.AuthService.roles.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$tc('profileForm.selectedRole')), 1),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_Dropdown, {
                    modelValue: _ctx.role,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.role) = $event)),
                    options: _ctx.AuthService.roles,
                    onChange: _cache[1] || (_cache[1] = event => { this.$forceUpdate(); this.updateCurrentRole(); }),
                    "option-value": "id",
                    optionLabel: "name",
                    placeholder: "Select...",
                    class: "md:w-14rem level-ddl field-role"
                  }, null, 8, ["modelValue", "options"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", {
                class: "label-first-name",
                innerHTML: _ctx.$tc('profileForm.labelFirstName')
              }, null, 8, _hoisted_12),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: _ctx.User.get().profile.firstName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.User.get().profile.firstName) = $event)),
                  class: "field-first-name"
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", {
                class: "label-last-name",
                innerHTML: _ctx.$tc('profileForm.labelLastName')
              }, null, 8, _hoisted_15),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: _ctx.User.get().profile.lastName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.User.get().profile.lastName) = $event)),
                  class: "field-last-name"
                }, null, 8, ["modelValue"])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "label-email",
            innerHTML: _ctx.$tc('profileForm.labelEmail')
          }, null, 8, _hoisted_17),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.User.get().email,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.User.get().email) = $event)),
              class: "field-email"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", {
                class: "label-job-title",
                innerHTML: _ctx.$tc('profileForm.labelJobTitle')
              }, null, 8, _hoisted_21),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: _ctx.User.get().profile.jobTitle,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.User.get().profile.jobTitle) = $event)),
                  class: "field-job-title"
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", {
                class: "label-job-level",
                innerHTML: _ctx.$tc('profileForm.labelJobLevel')
              }, null, 8, _hoisted_24),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.User.get().profile.level,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.User.get().profile.level) = $event)),
                  options: _ctx.Application.instance.levelItems,
                  onChange: _cache[7] || (_cache[7] = event => { this.$forceUpdate() }),
                  "option-value": "id",
                  optionLabel: "name",
                  placeholder: "Select...",
                  class: "level-ddl field-job-level"
                }, null, 8, ["modelValue", "options"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", {
                class: "label-phone",
                innerHTML: _ctx.$tc('profileForm.labelPhone')
              }, null, 8, _hoisted_28),
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: _ctx.User.get().profile.phone,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.User.get().profile.phone) = $event)),
                  class: "field-phone"
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", {
                class: "label-language",
                innerHTML: _ctx.$tc('profileForm.labelLanguage')
              }, null, 8, _hoisted_31),
              _createElementVNode("div", _hoisted_32, [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.User.get().profile.lianguage,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.User.get().profile.lianguage) = $event)),
                  options: _ctx.Application.instance.languages,
                  onChange: _cache[10] || (_cache[10] = event => { this.$forceUpdate(); }),
                  "option-value": "code",
                  optionLabel: "name",
                  placeholder: "Select...",
                  class: "level-ddl field-language"
                }, null, 8, ["modelValue", "options"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createVNode(_component_IciButton, {
            id: "submit",
            content: _ctx.constructLabel(_ctx.buttonSaveContent, _ctx.$t('profileForm.submit')),
            onButtonClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.submit())),
            btnStyle: "width: fit-content; height: auto; padding: 18px 24px!important;",
            dark: false
          }, null, 8, ["content"])
        ])
      ])
    ])
  ]))
}