<template>
  <div v-if="state && stateLabel[state]" :class="['status-label', state == 8 ? 'status-dark' : 'status-light']">{{
    stateLabel[state] }}</div>
  <div v-else class="status-label">N/A</div>

</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    state: {
      type: Number
    }
  },
  data() {
    return {
      stateLabel: {
        8: 'Active',
        9: 'Draft',
        10: 'Expired',
        11: 'Closed',
        1: 'Created',
        2: 'Invited',
        3: 'Failed to send invitation',
        4: 'Invitation accepted',
        5: 'Registered',
        6: 'Questionaire started',
        7: 'Questionaire submitted',
      } as {
        [key: number]: string;
      }
    }
  },
  setup(props) {
  },
});
</script>
<style scoped>
.status-label {
  width: fit-content;
  height: fit-content;
  line-height: 1;
  font-size: 14px;
  border-radius: 32px;
  border-width: 1px;
  border-style: solid;
  font-family: 'Public Sans';
  text-align: center;
  padding: 8px 16px;
  font-weight: 700;
  text-transform: uppercase;
  border-color: #0d3d52;
  border-color: #b8b8b8;
  color: #000
}

.status-dark {
  color: #ffffff;
  background-color: #0d3d52;
}

.status-light {
  color: #0d3d52;
  background-color: #ffffff;
  border-color: #0d3d52;
}
</style>
