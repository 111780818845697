<template>
    <div :id="small ? 'graph' : 'graph2Featured'" class="svgParentDiv col-span-5">
        <svg :class="class" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">

            <radialGradient id="graphHoverGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop class="centerGraphHoverColor" offset="75%" stop-color="#82CA74"></stop>
                <stop class="borderGraphHoverColor" offset="100%" stop-color="#629857"></stop>
            </radialGradient>

            <template v-if="bgLines">
                <line x1="100" y1="600" x2="1100" y2="600" style="stroke: #D4D4D4; stroke-width: 4;"></line>
                <line x1="600" y1="100" x2="600" y2="1100" style="stroke: #D4D4D4; stroke-width: 4;"></line>
                <line x1="100" y1="600" x2="1100" y2="600"
                    style="stroke: #D4D4D4; stroke-width: 16; stroke-dasharray: 5 calc(1040/11); stroke-dashoffset: 0;">
                </line>
                <line x1="600" y1="100" x2="600" y2="1100"
                    style="stroke: #D4D4D4; stroke-width: 16; stroke-dasharray: 5 calc(1040/11); stroke-dashoffset: 0;">
                </line>
            </template>

            <GraphCircles :circles="circles" :hoveredIndex="hoveredIndex" @hover="setHoveredIndex" />

            <!-- Render the re-entry hover circle -->
            <circle cx="600" cy="600" r="82.22800000000001" fill="#D4D4D4" class="reEntryHover" />
        </svg>

        <p
            style="position: absolute; font-size: 1.2em; font-weight: 600; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            5,08</p>
    </div>
</template>

<script>
import GraphCircles from '@/components/results/GraphCircles.vue'

export default {
    components: {
        GraphCircles
    },
    props: {
        small: {
            type: Boolean,
            default: false
        },
        circles: {
            type: Array,
            required: true
        },
        bgLines: {
            type: Boolean,
        },

        hoveredIndex: {
            type: Number,
            default: null
        },
        setHoveredIndex: {
            type: Function,
            required: true
        },
        class: {
            type: String,
            default: 'h-full'
        }
    },

};
</script>
