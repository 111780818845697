<template>
  <p class="m-0" v-html="$tc('questionnaireForm.statementContent')"/>

  <div class="grid m-0">
    <div class="col-12 p-0">
      <div class="question-field text-center font-bold" v-html="$tc('questionnaireForm.q' + this.questionIndex)" />
    </div>
  </div>

  <div class="flex m-0 px-8" style="align-items: center; gap: 20%">
    <div class="flex" style="flex-direction: column; align-items: center;  border-style:none;">
      <NotApplicableRadio style="display: flex; justify-content: center; height: fit-content; margin-top: -5px;" :click="setNotApplicable" :selected="checked"></NotApplicableRadio>
      <div class="pointer" style="min-width: 150px; margin-top: 34px;" v-html="$t('questionnaireForm.labelNotApplicable')"></div>
    </div>

    <div class="w-full">
      <Slider v-model="qvalue" :min="0" :step="1" :max="6" class="" style="width: 100% !important;" @change="changeEvent();" />

      <div class="flex mt-3 pointer" style="justify-content: space-between; width: calc(100% + 10px); ">
        <span @click="updateSlider(0)"></span>
        <span @click="updateSlider(1)">1</span>
        <span @click="updateSlider(2)">2</span>
        <span @click="updateSlider(3)">3</span>
        <span @click="updateSlider(4)">4</span>
        <span @click="updateSlider(5)">5</span>
        <span @click="updateSlider(6)">6</span>
      </div>

      <div class="flex pointer" style="justify-content: space-between; margin-top: 14px;">
        <div   @click="updateSlider(1)" style="margin-left: 20px" v-html="$t('questionnaireForm.labelCompletelyDisagree')"></div>
        <div @click="updateSlider(6)" style="margin-right: -42px" v-html="$t('questionnaireForm.labelCompletelyAgree')"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { User } from "@/data/User";
import NotApplicableRadio from "@/components/button/NotApplicableRadio.vue";

export default defineComponent({
  name: 'CustomInput',
  components: { NotApplicableRadio },
  props: {
    question: {
      type: String,
      default: ''
    },
    change: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      // @ts-ignore
      qvalue: User.get().invitation.response['q' + this.question] || 0,
      questionIndex: this.question,
      // @ts-ignore
      checked: (User.get().invitation.response['q' + this.question] == -1)
    }
  },
  methods: {
    changeEvent() {
      // @ts-ignore
      User.get().invitation.response['q' + this.question] = this.qvalue;
      // @ts-ignore
      this.checked = (User.get().invitation.response['q' + this.question] == -1)
      this.change(this.qvalue);
    },
    setNotApplicable() {
      this.checked = true;
      this.qvalue = -1;
      // @ts-ignore
      User.get().invitation.response['q' + this.question] = -1;
      this.change(this.qvalue)
    },
    updateSlider(value: number) {
      this.qvalue = value;
      this.changeEvent();
    }
  }
});
</script>

<style scoped>
.p-radiobutton .p-radiobutton-box.p-highlight {
  background: #FF6347 !important;
  /* This changes the background of the selected radio */
  border-color: #FF6347 !important;
  /* This changes the border of the selected radio */
}

/* Modifying the color of checked icon */
.p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  color: #FFFFFF !important;
  border-style:none;
  /* This changes the icon color of the selected radio */
}
.pointer{
  cursor:pointer
}
</style>
