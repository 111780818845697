import axios from "axios";
import {User} from "@/data/User";
import router from "@/router";
import {Application} from "@/data/Application";
import { Ref, ref } from "vue";

export class Response {
    public id: number|null = null;
    public userId: number|null = null;
    public companyActivity: string|null = null;
    public q1JobTitle: string |null = null;
    public q2Company: string |null =  null;
    public q3Level: number |null =  null;
    public q4YearsExperienceJob: number |null = null;
    public q5YearsInCompany: number |null = null;
    public q6YearsExperienceField: number |null = null;
    public q7NumberOfEmployees: number |null = null;
    public q8GrossRevenue: number |null = null;
    public q9ExecutiveReport: number |null = null;
    public q10: number|null = null;
    public q11: number|null = null;
    public q12: number|null = null;
    public q13: number|null = null;
    public q14: number|null = null;
    public q15: number|null = null;
    public q16: number|null = null;
    public q17: number|null = null;
    public q18: number|null = null;
    public q19: number|null = null;
    public q20: number|null = null;
    public q21: number|null = null;
    public q22: number|null = null;
    public q23: number|null = null;
    public q24: number|null = null;
    public q25: number|null = null;
    public q26: number|null = null;
    public q27: number|null = null;
    public q28: number|null = null;
    public q29: number|null = null;
    public q30: number|null = null;
    public q31: number|null = null;
    public q32: number|null = null;
    public q33: number|null = null;
    public q34: number|null = null;
    public q35: number|null = null;
    public q36: number|null = null;
    public q37: number|null = null;
    public q38: number|null = null;
    public q39: number|null = null;
    public q40: number|null = null;
    public q41: number|null = null;
    public q42: number|null = null;
    public q43: number|null = null;
    public q44: number|null = null;
    public q45: number|null = null;
    public q46: number|null = null;
    public q47: number|null = null;
    public q48: number|null = null;
    public q49: number|null = null;
    public q50: number|null = null;
    public q51: number|null = null;
    public q52: number|null = null;
    public q53: number|null = null;
    public q54: number|null = null;
    public q55: number|null = null;
    public q56: number|null = null;
    public q57: number|null = null;
    public q58: number|null = null;
    public q59: number|null = null;
    public q60: number|null = null;
    public q61: number|null = null;
    public q62: number|null = null;
    public q63: number|null = null;
    public q64: number|null = null;
    public q65: number|null = null;
    public q66: number|null = null;
    public q67: number|null = null;
    public q68: number|null = null;
    public q69: number|null = null;
    public q70: number|null = null;
    public q71: number|null = null;
    public q72: number|null = null;
    public q73: number|null = null;
    public q74: number|null = null;
    public q75: number|null = null;
    public q76: number|null = null;
    public q77: number|null = null;
    public q78: number|null = null;
    public q79: number|null = null;
    public q80: number|null = null;
    public q81: number|null = null;
    public q82: number|null = null;
    public q83: number|null = null;
    public q84: number|null = null;
    public q85: number|null = null;
    public q86: number|null = null;
    public q87: number|null = null;
    public q88: number|null = null;
    public q89: number|null = null;
    public q90: number|null = null;
    public q91: number|null = null;
    public q92: number|null = null;
    public q93: number|null = null;
    public q94: number|null = null;
    public q95: number|null = null;
    public q96: number|null = null;
    public q97: number|null = null;
    public q98: number|null = null;
    public q99: number|null = null;
    public q100: number|null = null;
    public q101: number|null = null;
    public q102: number|null = null;
    public q103: number|null = null;
    public q104: number|null = null;
    public q105: number|null = null;
    public q106: number|null = null;
    public q107: number|null = null;
    public q108: number|null = null;
    public q109: number|null = null;
    public q110: number|null = null;
    public q111: number|null = null;
    public q112: number|null = null;
    public q113: number|null = null;
    public q114: number|null = null;
    public q115: number|null = null;
    public q116: number|null = null;
    public q117: number|null = null;
    public q118: number|null = null;
    public q119: number|null = null;
    public q120: number|null = null;
    public q121: number|null = null;
    public q122: number|null = null;
    public q123: number|null = null;
    public q124: number|null = null;
    public q125: number|null = null;
    public q126: number|null = null;
    public q127: number|null = null;
    public q128: number|null = null;
    public q129: number|null = null;
    public q130: number|null = null;
    public q131: number|null = null;
    public q132: number|null = null;
    public q133: number|null = null;
    public q134: number|null = null;
    public q135: number|null = null;
    public q136: number|null = null;
    public q137: number|null = null;
    public q138: number|null = null;
    public q139: number|null = null;
    public q140: number|null = null;
    public q141: number|null = null;
    public q142: number|null = null;
    public q143: number|null = null;
    public q144: number|null = null;
    public q145: number|null = null;
    public q146: number|null = null;
    public q147: number|null = null;
    public q148: number|null = null;
    public q149: number|null = null;

    private _progress: Ref<number>;

    constructor(){
        this._progress = ref(0);
        this.calculateProgress()
    }
    public get progress(): number {
        return this._progress.value;
    }
    public set progress(value: number) {
        this._progress.value = value;
    }

    public getQuestionValue(q: string | number) {
        const key = `q${q}` as keyof Response;
        return this[key];
    }

    public state = () => {
        if (User.get().invitation.response.id != null) {
            switch (User.get().invitation.state) {
                case 6:
                    return "In progress";
                case 7:
                    return "Finished";
                default:
                    return "Not started";
            }
        } else {
            return User.get().invitation.state != 7 ? "Not started" : "n/a";
        }
    }

    public deadline = () => {
        if (User.get().invitation.response.id != null) {
            return "3 days";
        } else {
            return "n/a";
        }
    }

    public calculateProgress(): void {
        const totalQuestions = 149; 
        const answeredCount = Object.keys(this)
            .filter(key => key.startsWith('q') && !isNaN(parseInt(key.slice(1))))
            .reduce((count, key) => count + (this[key as keyof Response] != null ? 1 : 0), 0);

        this.progress = Math.round((answeredCount / totalQuestions) * 100);
    }
    
    public updateProgress(): void {
        this.calculateProgress();
    }

    public progressAsString = () => {
        this.calculateProgress()
        return User.get().invitation.state != 7 ? this.progress + '% complete' : "n/a";
    }

    public save = () => {
        axios.post(Application.instance.apiEndPoint + '/questionnaire/response', this)
            .then(response => {
                this.id = response.data.id;
            })
            .catch(err => {
                // console.log(err);
            });
            this.updateProgress();
    }

    public submit = () => {
        axios.post(Application.instance.apiEndPoint + '/questionnaire/response/submit', this)
            .then(response => {
                User.get().invitation.state = 7;
                router.push('/')
            })
            .catch(err => {
                // console.log(err);
            });
            this.updateProgress()
    }
}
