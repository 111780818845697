import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a49835a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-respondent-dashboard" }
const _hoisted_2 = {
  class: "flex",
  style: {"align-items":"center","justify-content":"space-between"}
}
const _hoisted_3 = { class: "welcome-title-respondent-dashboard" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "back-to-all-clients" }
const _hoisted_7 = { class: "respondent-dashboard-block" }
const _hoisted_8 = { style: {"position":"relative","width":"100%","height":"100%"} }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { style: {"position":"absolute","top":"140px","bottom":"100px","left":"40px","right":"40px","min-width":"1400px","border-radius":"10px","min-height":"90px","background-color":"#ffffff"} }
const _hoisted_11 = { style: {"position":"relative","width":"100%","height":"100%"} }
const _hoisted_12 = { style: {"position":"absolute","top":"0px","left":"0px","right":"0px","height":"50px","background-color":"#F3F4F8","border-radius":"10px","min-width":"1000px"} }
const _hoisted_13 = { style: {"position":"relative","width":"100%","height":"100%","min-width":"1000px"} }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { style: {"position":"absolute","top":"50px","left":"0px","right":"0px","bottom":"10px"} }
const _hoisted_18 = { style: {"position":"relative","width":"100%","min-width":"1000px","height":"70px","background-color":"#FFFFFF","border-bottom-left-radius":"10px","border-bottom-right-radius":"10px"} }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { style: {"float":"left","width":"30%","min-width":"300px","font-family":"'Public Sans'","font-size":"16px","line-height":"70px","text-align":"left","padding-top":"20px"} }
const _hoisted_21 = { style: {"float":"left","width":"200px","min-width":"300px","font-family":"'Public Sans'","font-size":"16px","line-height":"70px","text-align":"left"} }
const _hoisted_22 = { style: {"width":"200px","height":"40px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IciButton = _resolveComponent("IciButton")!
  const _component_IciStatus = _resolveComponent("IciStatus")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.selectedClient.id != 0)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              innerHTML: _ctx.selectedClient.name
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.selectedClient.id == 0)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              innerHTML: _ctx.$t('companyForm.titleAddNewClient')
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_IciButton, {
          id: "backToDashboard",
          content: _ctx.constructLabel(_ctx.buttonBackToAllClients, _ctx.$t('companyForm.buttonBackToDashboard')),
          onButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backToClientDashboard(_ctx.selectedClient.id))),
          btnStyle: "width: fit-content; height: auto; padding: 18px 24px!important;"
        }, null, 8, ["content"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          style: {"position":"absolute","top":"40px","left":"40px","right":"40px","height":"50px","border-radius":"10px","background-color":"#fbfaff","font-family":"'Public Sans'","font-weight":"bold","text-align":"left","padding-left":"40px","line-height":"50px"},
          innerHTML: _ctx.$t('companyForm.titleDashboard') + ' > ' + _ctx.$t('companyForm.titleProjectOverview') + ' > Innovation Capability Index Questionnaire'
        }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", {
                style: {"float":"left","width":"40%","min-width":"300px","font-family":"'Public Sans'","font-weight":"bold","font-size":"16px","line-height":"50px","text-align":"left","padding-left":"30px"},
                innerHTML: _ctx.$t('companyForm.tableHeaderRespondentName')
              }, null, 8, _hoisted_14),
              _createElementVNode("div", {
                style: {"float":"left","width":"30%","min-width":"300px","font-family":"'Public Sans'","font-weight":"bold","font-size":"16px","line-height":"50px","text-align":"left"},
                innerHTML: _ctx.$t('companyForm.tableHeaderStatus')
              }, null, 8, _hoisted_15),
              _createElementVNode("div", {
                style: {"float":"left","width":"30%","min-width":"300px","font-family":"'Public Sans'","font-weight":"bold","font-size":"16px","line-height":"50px","text-align":"left"},
                innerHTML: _ctx.$t('companyForm.tableHeaderProgressOverview')
              }, null, 8, _hoisted_16)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedClient?.getActiveOrLatestQuestionnaire().respondents, (repondent) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", {
                  style: {"float":"left","width":"40%","min-width":"300px","font-family":"'Public Sans'","font-size":"16px","line-height":"50px","text-align":"left","padding-left":"20px"},
                  innerHTML: repondent.email
                }, null, 8, _hoisted_19),
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_IciStatus, {
                    state: repondent.state.id
                  }, null, 8, ["state"])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_ProgressBar, {
                      value: repondent.response?.progress
                    }, null, 8, ["value"])
                  ])
                ])
              ]))
            }), 256))
          ])
        ])
      ])
    ])
  ]))
}