<template>
  <div style="font-family: 'Public Sans'; text-align: left; margin-bottom: 32px">
    Demographics information is taken from your profile, together with the information provided by your company.
  </div>


  <div v-if="activeQuestion < 10" class="grid grid-rows-5  grid-flow-col gap-4 text-start">
    <div v-for="question in questionnaireFields" :key="question.id" class="">
      <div class="font-bold">{{ $tc(question.label) }}</div>
      <div>
        <InputText v-if="question.type === 'text'" type="text" :value="formData[question.field]"
          @input="(event) => handleInputChange(question.field, event)" class="demographics-field" />
        <Dropdown v-else :modelValue="formData[question.field]"
          @update:modelValue="(value) => handleDropdownChange(question.field, value)"
          :options="getDropdownOptions(question.options)" option-value="id" optionLabel="name" placeholder="Select..."
          class="md:w-14rem level-ddl demographics-field" />
      </div>
    </div>

  </div>

</template>

<script lang="ts">
// @ is an alias to /src
import { User } from "@/data/User";
import { Application } from "@/data/Application";
import { reactive } from "vue";

import { defineComponent, PropType } from 'vue';

interface Response {
  [key: string]: any;
  save: () => void;
}

function generateQuestionnaireFields() {
  const questions = [
    { id: 'q1', label: 'questionnaireForm.q1', field: 'q1JobTitle', type: 'text' },
    { id: 'q2', label: 'questionnaireForm.q2', field: 'q2Company', type: 'text' },
    { id: 'q3', label: 'questionnaireForm.q3', field: 'q3Level', type: 'dropdown', options: 'levelItems' },
    { id: 'q4', label: 'questionnaireForm.q4', field: 'q4YearsExperienceJob', type: 'text' },
    { id: 'q5', label: 'questionnaireForm.q5', field: 'q5YearsInCompany', type: 'text' },
    { id: 'q6', label: 'questionnaireForm.q6', field: 'q6YearsExperienceField', type: 'text' },
    { id: 'q7', label: 'questionnaireForm.q7', field: 'q7NumberOfEmployees', type: 'dropdown', options: 'nummberOfEmployeesItems' },
    { id: 'q8', label: 'questionnaireForm.q8', field: 'q8GrossRevenue', type: 'dropdown', options: 'grossRevenueItems' },
    { id: 'q9', label: 'questionnaireForm.q9', field: 'q9ExecutiveReport', type: 'dropdown', options: 'grossExecutiveReportItems' }
  ];

  const UserInvitationResponsePath = `User.get().invitation.response.`

  return questions.map(q => ({
    ...q,
    modelValue: UserInvitationResponsePath + q.field,
    dropdownModel: q.type === 'dropdown' ? q.field : null
  }));
}


export default defineComponent({
  computed: {
    User() {
      return User
    },
    Application() {
      return Application
    }
  },
  props: {
    activeQuestion: {
      type: Number,
      required: true
    }
  },

  setup(props) {
    // Generate questionnaire fields
    const questionnaireFields = generateQuestionnaireFields();
    
    // Init reactive form and fill with data if exist
    const formData = reactive(
      questionnaireFields.reduce((acc, field) => {
        acc[field.field] = (User.get().invitation.response as Response)[field.field] || '';
        return acc;
      }, {} as Record<string, any>)
    );

    // Update User invitation responses with form data
    const autoSave = () => {
      Object.entries(formData).forEach(([field, value]) => {
        (User.get().invitation.response as Response)[field] = value;
      });
      (User.get().invitation.response as Response).save();
    };

    const handleInputChange = (field: string, event: Event) => {
      if (event.target && event.target instanceof HTMLInputElement) {
        formData[field] = event.target.value;
        autoSave();
      }
    };

    const handleDropdownChange = (field: string, value: any) => {
      formData[field] = value;
      autoSave();
    };

    const getDropdownOptions = (optionsKey: string | undefined) => {
      if (optionsKey && optionsKey in Application.instance) {
        return (Application.instance as any)[optionsKey];
      }
      return [];
    };

    return {
      questionnaireFields, formData,
      autoSave,
      handleInputChange,
      handleDropdownChange,
      getDropdownOptions
    }
  },
});
</script>
<style>
.level-ddl {
  width: 100%;
  height: 20px;
  line-height: 20px;
}

.demographics-field {
  width: 95% !important;
  height: 40px;
}

.label-number-1 {
  font-family: "Public Sans";
  height: 20px;
  line-height: 20px;
  text-align: right;
}

.question-field-1 {
  font-family: "Public Sans";
  text-align: left;
  height: 20px;
  line-height: 20px;
}

.number-field {
  font-family: "Public Sans";
  width: 100px;
}

.paragraph {
  font-family: "Public Sans";
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  margin-left: -30px;
  height: 50px;
}
</style>
