import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5db639f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "status-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.state && _ctx.stateLabel[_ctx.state])
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['status-label', _ctx.state == 8 ? 'status-dark' : 'status-light'])
      }, _toDisplayString(_ctx.stateLabel[_ctx.state]), 3))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, "N/A"))
}